.main{
  display: flex;
  min-height: 1000px;
  background-color: #081417;
  justify-content: flex-start;
}

.mainContainer{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.verticalNavbar{
  font-family: Roboto, sans-serif;
  height: 100vh;
  min-height: 1000px;
  width: 400px;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #F7EBE8;
  transition: 0.3s ease;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-right: 15px solid #499177; 
  background-color: #081417;
}

.verticalNavbarMobile{
  display: flex;
  align-self: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  min-width: 300px;
  width: 30%;
  height: 60px;
  background-color: #F7EBE8;
  border-radius: 30px;
  margin-bottom: 20px;
}

.profileMain{
  font-family: Roboto, sans-serif;
  height: 100%;
  max-width: 1300px;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profileMainMobile{
  font-family: Roboto, sans-serif;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.everythingAboutMe{
  max-width: 800px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutMeText{
  font-size: 32px;
}

.aboutMeTextMobile{
  font-size: 20px;
}

.portraitPicture{
  /* padding: 100px; */
}

.portraitPictureMobile{
  width: 300px;
  height: 450px;
}

.personalInfo{
  padding:50px;
  height: 30%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-self: center;
  color: #F7EBE8;
}

.personalInfoMobile{
  height: 30%;
  display: flex;
  flex-direction: column;
  align-self: center;
  color: #F7EBE8;
}

.aboutMe{
  /* padding: 70px; */
  color: #F7EBE8;
  font-size: 26px;
  text-align: center;
}

.tabs{
  height: 100%;
  color: #F7EBE8;
  background-color: #081417;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 300;
  font-size: 20px;
}

.tabsMobile{
  width: 70%;
  min-width: 250px;
  display: flex;
  justify-content: space-between;
}

.tabsLink{
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 30px;
  width: 100%;
  color: #F7EBE8;
  transition: 0.5s;
}

.tabsLinkMobile{
  display: flex;
  align-items: center;
  padding: 10px;
  transition: 0.5s;
}

.tabsLink:hover{
  background-color: #F7EBE8;
  color: #081417;
  transition: 0.5s;
}

.tabsLinkMobile:hover{
  background-color: #081417;
  color: #081417;
  transition: 0.5s;
}

.tabsLinkMobile:focus{
  background-color: #081417;
  color: #081417;
  transition: 0.5s;
}

.tabsText{
  font-weight: 300;
  font-size: 35px;
  padding: 0 20px;
}

.tabsIcon{
  height: 40px; 
  width: 40px;
}

.fullName{
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.workTitle{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.workTitleMobile{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  
}

.name{
  display: flex;
  align-self: flex-start;
  font-size: 72px;
}

.surname{
  display: flex;
  align-self: flex-start;
  font-size: 72px;
  font-weight: 600;
  margin-left: 15px;
}

.nameMobile{
  display: flex;
  align-self: flex-start;
  font-size: 48px;
}

.surnameMobile{
  display: flex;
  align-self: flex-start;
  font-size: 48px;
  font-weight: 600;
  margin-left: 15px;
}

.position{
  display: flex;
  align-self: flex-end;
  font-size: 26px;
}

.positionMobile{
  display: flex;
  align-self: flex-end;
  font-size: 20px;
}

.education{
  display: flex;
  align-self: flex-end;
  font-size: 26px;
}

.educationMobile{
  display: flex;
  align-self: flex-end;
  font-size: 20px;
}

.experienceMain{
  font-family: Roboto, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  color: #F7EBE8;
  padding: 0 50px;
}

.experienceMainMobile {
  font-family: Roboto, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  color: #F7EBE8;
  padding: 0 10px;
}

.experienceContainer{
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.myExperience{
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}


.experienceHeader{
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.experienceBody{
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  border-left: 10px solid #499177;
  border-right: 10px solid #499177;
  width: 400px;
}

.experienceBodyMobile{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  border-left: 10px solid #499177;
  border-right: 10px solid #499177;
  width: 80%;
  max-width: 400px;
}

.bulletPoint{
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 40px;
}

.experienceSection{
  display: flex;
  flex-direction: row;
}

.experienceTitle{
  font-size: 32px;
  font-weight: 600;
}

.experienceFirm{
  font-size: 26px;
  font-weight: 300;
}

.projectsMain{
  font-family: Roboto, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  color: #F7EBE8;
  padding: 0 50px;
}

.projectsContainer{
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.myProject{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.projectSection{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectSectionMobile{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectHeader{
  font-size: 32px;
  font-weight: 600;
}

.projectBody{
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 10px solid #499177;
  border-right: 10px solid #499177;
  width: 400px;
  height: fit-content;
}

.projectBodyMobile{
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 10px solid #499177;
  border-right: 10px solid #499177;
  width: 80%;
  max-width: 400px;
  height: fit-content;
}

.bulletPoint{
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 40px;
}

.skillsMain{
  font-family: Roboto, sans-serif;
  height:100%;
  display: flex;
  align-items: center;
  color: #F7EBE8;
  padding: 0 50px;
}

.skillsMainMobile{
  font-family: Roboto, sans-serif;
  height:100%;
  display: flex;
  align-items: center;
  color: #F7EBE8;
  padding: 0;
}

.softSkills{
  padding: 10px 0;
}

.technologySkills{
  padding: 10px 0;
}

.skillsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 50px;
  flex-direction: row;
  flex-wrap: wrap;
}

.skillsContainerMobile{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  flex-direction: row;
  flex-wrap: wrap;
}

.skillsTitle{
  display: flex;
  justify-content: center;
  font-size: 48px;
  font-weight: 600;
}

.skillsSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
}

.skillLogoImg{
  height: 120px;
  width: 120px;
  padding: 10px;
}
.skillLogoImgMobile{
  height: 100px;
  width: 100px;
  padding: 10px;
}

.skillName{
  font-size: 20px;
}

.contactInfo{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  align-self: center;
}

.contactSection{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}